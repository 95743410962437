import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


function Footer() {

	const {t} = useTranslation();

    return (
        <div>
            
            <footer className="footer">
	
	<div className="footer-middle">
		<div className="container">
			<div className="row">
				<div className="grid4 tb12 sm12 footer-info">					 
					<div className="footer-title">{t('LegalInfoLabel')}</div>
					<p>{t('LegalInfoString1')}</p>
					<p><span dangerouslySetInnerHTML={{__html: t('LegalInfoString2') }}  /></p>
				</div>
				<div className="grid8 tb12">
					<div className="row">
						<div className="grid4 sm6">
							<div className="footer-title">{t('Contacts')}</div>
							<p><a href="mailto:info@o-pocket.org">info@o-pocket.org</a></p>							
						</div>
						<div className="grid4 sm6">
							<div className="footer-title">{t('Menu')}</div>
							<ul>
								<li><Link to="/">{t('Home')}</Link></li>
								<li><Link to="/about">{t('About')}</Link></li>
								{/*<li><Link to="/loot">{t('Loot')}</Link></li>*/}
								{/*<li><Link to="/reports">{t('Reports')}</Link></li>*/}
								<a href="https://www.instagram.com/a85avua/">{t('Reports')}</a>
								<li><Link to="/faq">{t('Faq')}</Link></li>
								<li><Link to="/contacts">{t('Contacts')}</Link></li>
							</ul>
						</div>						
					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="copyright">
		<div className="container">
			&copy; 2022-2024 Operative Pocket Foundation
		</div>
	</div>
</footer>
            
        </div>
    )
}

export default Footer;