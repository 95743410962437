import { useTranslation } from "react-i18next";
import Support from "./Support";
 

function Main() {

     const [t] = useTranslation();

    return (
        <div>
            <section className="main-section">
	<div className="container">
		
		<div className="article">
			
		    <p dangerouslySetInnerHTML={{__html: t('LastUpdateTime')}}></p>

			<p dangerouslySetInnerHTML={{__html: t('MainInternal')}}></p>
			 
		</div>

		<hr />

		<div className="article">
			<h2 dangerouslySetInnerHTML={{__html: t('SupportEasy')}}></h2>
		</div>

		<Support />

		<hr />

		<h2 dangerouslySetInnerHTML={{__html: t('BoughtEarly')}}></h2>
		<div className="article">
			<ul dangerouslySetInnerHTML={{__html: t('BoughtEarlyList')}}>
				
			</ul>
		</div>


		 
		 

	</div>
</section>
        </div>
    )
}

export default Main;